<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Signing in</h3>
	<p><span v-html="app_name"/> users can <span v-html="link('framework_index', 'view')"></span> <b>public</b> frameworks, and <span v-html="link('treeview', 'browse')"></span>, <span v-html="link('search', 'search')"></span>, <span v-html="link('pinning', 'pin')"></span>, and <span v-html="link('export_options', 'export/print')"></span> {{comp_noun+'s'}} for these frameworks, <b>without signing in to <span v-html="app_name"/></b>.</p>
	<p>You <b>must be signed in</b> to do the following in <span v-html="app_name"/>:</p>
	<ul>
		<li>View frameworks whose adoption status is <b>private</b>. To view a private framework, you must also have been <span v-html="link('user_roles_admin', 'granted viewer rights')"></span> to the framework by an administrator of the framework.</li>
		<li><b>Make <span v-html="link('add_comments', 'comments')"></span></b> on items in frameworks.<ul>
			<li>Note that a signed-in user can make comments on any public framework <i>or</i> any private framework they have been granted viewing rights to.</li>
			<li>Also note that any signed-in user can create a <span v-html="link('comment_groups', 'comment group')"></span> and invite other users to the group, or can themselves be invited to a comment group.</li>
		</ul></li>
		<li><span v-html="link('editing', 'Edit')"></span> a framework or <span v-html="link('user_roles_admin', 'grant')"></span> viewer or editor rights to other users for a framework (if you have editing and/or admin rights to the framework).</li>
	</ul>
	<p><img srcset="/docimages/signing_in-1.png 2x" class="k-help-img float-right">Click the SIGN IN button in the upper-right corner of the window to sign in to <span v-html="app_name"/>.</p>
	<p>Additional help is available with <span v-html="link('roles_system', 'system user roles')"></span>, <span v-html="link('roles_framework', 'framework user roles')"></span>, and <span v-html="link('user_roles_admin', 'assigning user roles')"></span>.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	